import React, { useEffect } from "react"

import Wishlist from "../../../components/account/pages/wishlist"
import { useTranslationContext } from "../../../context/TranslationContext"

const canonicals = {
  ["en-US"]: "/account/my-wishlist",
  ["de-DE"]: "/de/konto/meine-wunschliste",
}
const WishlistPage = () => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "de-DE",
      canonicals: canonicals,
    })
  }, [setTranslationContext])
  return <Wishlist />
}

export default WishlistPage
